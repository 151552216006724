
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
  export default class LeadsProgress extends Vue {
  @Prop({ type: String, default: null, required: true }) title!: string
  @Prop({
    type: Object, default: () => ({}),
  }) leads!: Record<string, any>

  // Methods
  getExecutiveLeads (data) {
    // Primera pasada: calcular los totales por ejecutivo
    const result = data.reduce((acc, lead) => {
      const executiveName = `${lead.executive?.person?.firstName} ${lead.executive?.person?.surname}`
      const email = lead.executive?.person?.user?.email

      if (!acc[email]) {
        acc[email] = { executive: executiveName, total: 0, active: 0, closed: 0 }
      }

      acc[email].total += 1

      // Verifica si el lead tiene algún deal activo
      const hasActiveDeals = lead.deals.some(deal => deal.closingReason === null)
      if (hasActiveDeals) {
        acc[email].active += 1
      }

      // Verifica si el lead tiene algún deal cerrado
      const hasClosedDeals = lead.deals.some(deal => deal.closingReason !== null)
      if (hasClosedDeals) {
        acc[email].closed += 1
      }

      return acc
    }, {})

    return Object.values(result)
  }

  calculatedFull (closed, total) {
    if (closed === total) return 100 // Si todos están cerrados, la barra está completa
    if (!closed) return 0 // Si ninguno está cerrado, la barra está vacía
    return Math.round((closed / total) * 100)
  }

  // Getters
  get summary (): Record<string, any> {
    const { leads } = this

    if (!leads?.records?.length) return []

    return this.getExecutiveLeads(leads.records)
  }

  get active () {
    const { summary } = this
    return summary.reduce((acc, lead) => acc + lead.active, 0)
  }

  get closed () {
    const { summary } = this
    return summary.reduce((acc, lead) => acc + lead.closed, 0)
  }

  // Watchers
  }
