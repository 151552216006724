
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { Stock } from '@/entities/sales'
  import { GForm } from '@/components/forms/GForm'
  import GLoading from '../core/GLoading.vue'
  import FileView from '../core/files/FileView.vue'
  import { EnablementTask } from '@/entities/purchase'

@Component({
  components: {
    GLoading,
    FileView,
  },
})
  export default class StockDocumentDetails extends GForm {
  @Prop({ type: Object, default: () => ({}) }) value!: Stock
  @Prop({ type: String, default: 'purple' }) color!: string
  @Prop({ type: String, default: 'fab' }) buttonType!: string
  @Prop({ type: String, default: '' }) buttonLabel!: string

  documentation = []
  componentDocumentation = []
  openDocuments = false
  idProcessInspectedComponent = null
  enablementInspectionComponent = []
  enablementTaskInspectionComponentCirculationPermit = []
  enablementTaskInspectionComponentSoap = []
  enablementTaskInspectionComponentTechnicalReview = []
  loading = false
  task: EnablementTask| null = null

  get oldCirculationPermit () {
    const { enablementInspectionComponent, enablementTaskInspectionComponentCirculationPermit } = this
    const taskComponent = enablementTaskInspectionComponentCirculationPermit?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
    if (!taskComponent?.length) return null

    return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
  }

  get circulationPermit () {
    const { enablementInspectionComponent, enablementTaskInspectionComponentCirculationPermit } = this

    const taskComponent = enablementTaskInspectionComponentCirculationPermit?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
    const enablementComponent = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')

    if (taskComponent?.length) {
        return taskComponent
    }

    return enablementComponent
  }

  get oldSoap () {
    const { enablementInspectionComponent, enablementTaskInspectionComponentSoap } = this

    const taskComponent = enablementTaskInspectionComponentSoap?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')

    if (!taskComponent?.length) return null

    return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
  }

  get soap () {
    const { enablementInspectionComponent, enablementTaskInspectionComponentSoap } = this

    const taskComponent = enablementTaskInspectionComponentSoap?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
    const enablementComponent = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')

    if (taskComponent?.length) {
        return taskComponent
    }

    return enablementComponent
  }

  get oldTechnicalReview () {
    const { enablementInspectionComponent, enablementTaskInspectionComponentTechnicalReview } = this
    const taskComponent = enablementTaskInspectionComponentTechnicalReview?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
    if (!taskComponent?.length) return null

    return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
  }

  get technicalReview () {
    const { enablementInspectionComponent, enablementTaskInspectionComponentTechnicalReview } = this

    const taskComponent = enablementTaskInspectionComponentTechnicalReview?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
    const enablementComponent = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')

    if (taskComponent?.length) {
        return taskComponent
    }

    return enablementComponent
  }

  get lastMaintenance () {
    const { value } = this
    return value?.auto.lastMaintenanceValue
  }

  findFile (key) {
    return this.documentation?.find(doc => doc.fieldKey === key)
  }

  async getFiles (stock) {
    const { viewDetails: { idEnablement } } = stock

    this.componentDocumentation = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
      force: true,
    })
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
      force: true,
    })
    this.idProcessInspectedComponent = process?.[0]?.id
    this.enablementInspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: idEnablement } },
            { process: { table_name: { _eq: 'enablement' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })
    if (this.oldCirculationPermit?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.oldCirculationPermit[0].idInspectedComponent, 'oldCirculationPermit', process?.[0]?.id)
    }
    if (this.circulationPermit?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.circulationPermit[0].idInspectedComponent, 'circulationPermit', process?.[0]?.id)
    }
    if (this.soap?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.soap[0].idInspectedComponent, 'soap', process?.[0]?.id)
    }
    if (this.oldSoap?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.oldSoap[0].idInspectedComponent, 'oldSoap', process?.[0]?.id)
    }
    if (this.technicalReview?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.technicalReview[0].idInspectedComponent, 'technicalReview', process?.[0]?.id)
    }
    if (this.oldTechnicalReview?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.oldTechnicalReview[0].idInspectedComponent, 'oldTechnicalReview', process?.[0]?.id)
    }

    await this.getOtherDocuments()
    await this.getMaintenance()
  }

  async setPhotoToForm (idProcessRecord, fieldKey, idProcess) {
    if (!idProcessRecord) return

    const photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    this.documentation.push({ fieldKey, photo })
  }

  async getOtherDocuments () {
    const { value: stock } = this
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
      force: true,
    })
    const { cav, report, penalties } = await this.findAssociatedFiles(process?.[0]?.id, stock.viewDetails.idNegotiation)

    this.documentation.push({ fieldKey: 'cav', photo: cav })
    this.documentation.push({ fieldKey: 'report', photo: report })
    this.documentation.push({ fieldKey: 'penalties', photo: penalties })
  }

  async findAssociatedFiles (idProcess, id) {
    if (!id) return {}
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'cav' } } }] },
      force: true,
    })

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'legal_report' } } }] },
      force: true,
    })

    const penalties = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'penalty_certificate' } } }] },
      force: true,
    })

    return {
      cav,
      report,
      penalties,
    }
  }

  async getMaintenance () {
    const idAuto = this.value?.auto?.id
    const maintenances = await this.fetchData({
      query: { name: 'find', model: 'Maintenance', order: { mileage: 'desc' } },
      filter: { auto: { id: { _eq: idAuto } } },
      force: true,
    })

    this.value.auto.maintenances = maintenances

    const maintenanceProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'maintenance' } },
      force: true,
    })

    const photos = await Promise.all(maintenances.map(async maintenance => {
      return this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: maintenance.id } },
            { parameter: { process: { id: { _eq: maintenanceProcess?.[0]?.id } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })
    }))

    this.documentation.push({ fieldKey: 'maintenances', photo: photos.flat() })
  }

  @Watch('openDocuments', { immediate: true, deep: true })
  async onValueChanged (val) {
    if (!val) return
    this.loading = true

    const tasks = (await this.fetchData({
      query: { name: 'find', model: 'EnablementTask' },
      filter: { id_enablement: { _eq: this.value.viewDetails.idEnablement } },
      force: true,
    }))

    const taskCirculation = tasks?.find(t => t?.type.name === 'circulation_permit')
    if (taskCirculation?.id) {
      await this.searchTaskQualification(taskCirculation, 'enablementTaskInspectionComponentCirculationPermit')
    }

    const taskSoap = tasks?.find(t => t?.type.name === 'soap')

    if (taskSoap?.id) {
      await this.searchTaskQualification(taskSoap, 'enablementTaskInspectionComponentSoap')
    }

    const taskTechnicalReview = tasks?.find(t => t?.type.name === 'technical_review')

    if (taskTechnicalReview?.id) {
      await this.searchTaskQualification(taskTechnicalReview, 'enablementTaskInspectionComponentTechnicalReview')
    }

    this.getFiles(this.value)
    this.loading = false
  }

  async searchTaskQualification (task, field) {
    this[field] = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: task.id } },
            { process: { table_name: { _eq: 'enablement_task' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })
  }

  openFile (fileProcess) {
    fileProcess.map(file => {
      this.openLink(file.file.uri)
    })
  }

  getDate (filesProcess, isSerialized = true) {
    if (!filesProcess?.length) return ''

    const fileProcess = filesProcess[0]

    if (!isSerialized) {
      return fileProcess?.file?.expirationDate?.format('DD/MM/YYYY')
    }

    const serialization = fileProcess?.file?.serialization

    return serialization?.emissionDate || fileProcess?.createdAt?.format('DD/MM/YYYY')
  }

  getInspectionViewByOrder (inspectionViews, order: number) {
    return inspectionViews?.find(iv => iv.parameter.order === order)
  }
  }
